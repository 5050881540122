import React, { Component, Suspense } from 'react';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import * as Loader from "react-loader-spinner";

// Import other components...

// Import App component after defining AppWithRouter
const App = React.lazy(() => import('./App')); // Define App component after importing the others
// const Error404 = React.lazy(() => import('./views/error'));

export default class Routing extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Suspense
        fallback={
          <div className="loader">
            <Loader.TailSpin type="Oval" color="#00BFFF" height={80} width={80} />
          </div>
        }
      >
        <BrowserRouter>
          <Routes>
            <Route path="/:gameID?" element={<App />} />
            {/* <Route path="/game/error" element={<Error404 />} /> */}
          </Routes>
        </BrowserRouter>
      </Suspense>
    );
  }
}
