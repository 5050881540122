import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Routing from './routes'; // Import the Routing component instead of App
import reportWebVitals from './reportWebVitals';


ReactDOM.render(
  <React.StrictMode>
    <Routing />
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
